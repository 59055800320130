import { useState } from 'react';
import { ChatBubble, ChatBubbleMessage } from '../chat/chat-bubble';
import { LongPressEventType, useLongPress } from 'use-long-press';
import { useIsMobile } from '@/hooks/useIsMobile';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown-menu';
import DislikeReaction from '@/assets/icons/reaction-dislike.svg';
import LikeReaction from '@/assets/icons/reaction-like.svg';
import { trpc } from '@/utils/trpc';
import { FluentEmoji } from '@lobehub/fluent-emoji';
import { createPortal } from 'react-dom';

type Props = {
  message: string;
  messageId: number;
  segmentIndex: number;
  initialReaction?: string;
};

export function ActionBubble({ message, messageId, segmentIndex, initialReaction }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  const bind = useLongPress(
    () => {
      setIsOpen(true);
    },
    {
      threshold: 400,
      detect: isMobile ? LongPressEventType.Touch : LongPressEventType.Mouse,
    }
  );
  const [reactionType, setReactionType] = useState(initialReaction);
  const { mutateAsync: addReaction } = trpc.chat.addMessageReaction.useMutation({
    onMutate: (input) => {
      setReactionType(input.reaction);
    },
  });

  return (
    <>
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <ChatBubble variant={'received'} className="select-none">
          <ChatBubbleMessage variant={'received'} className="cursor-pointer opacity-80" {...bind()}>
            {message}
          </ChatBubbleMessage>
          <DropdownMenuTrigger>
            <span className="hidden" />
          </DropdownMenuTrigger>

          {!!reactionType &&
            (reactionType === 'like' ? (
              <div className="absolute right-0 top-0">
                <FluentEmoji emoji="😀" type="anim" className="size-6" />
              </div>
            ) : (
              <div className="absolute right-0 top-0">
                <FluentEmoji emoji="🥲" type="anim" className="size-6" />
              </div>
            ))}
        </ChatBubble>

        <DropdownMenuContent className="animate-in flex w-48 flex-col gap-2">
          <DropdownMenuItem
            className="flex items-center gap-2"
            onSelect={() => {
              addReaction({
                messageId,
                segmentIndex,
                reaction: 'like',
              });
            }}
          >
            <LikeReaction className="size-8" />
            <span>喜欢</span>
          </DropdownMenuItem>

          <DropdownMenuItem
            className="flex items-center gap-2"
            onSelect={() => {
              addReaction({
                messageId,
                segmentIndex,
                reaction: 'dislike',
              });
            }}
          >
            <DislikeReaction className="size-8" />
            <span>不喜欢</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {isOpen &&
        createPortal(
          <div className="fixed inset-0 z-50 flex size-full select-none items-center justify-center bg-black/40" />,
          document.body
        )}
    </>
  );
}
